import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import ChatCard from './ChatCard';
import MenuCard from './MenuCard';
import TutorialOverlay from './TutorialOverlay';

function GPTPage({ openPrivacyOverlay, userInfo }) {  
    const [showTutorialOverlay, setShowTutorialOverlay] = useState(false); 
    const [tutorialShown, setTutorialShown] = useState(false); 
    const [debugMode, setDebugMode] = useState(false);  // Move debugMode state here
    const location = useLocation();  // Get the current location

    useEffect(() => {
        // Check for the debug mode in query parameters
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.get('debug') === 'true') {
            setDebugMode(true);
        } else {
            setDebugMode(false);
        }
    }, [location]);

    useEffect(() => {
        if (location.pathname === '/GPT' && !tutorialShown) {
            setShowTutorialOverlay(true);
            setTutorialShown(true); 
        }
    }, [location.pathname, tutorialShown]);

    const closeTutorialOverlay = () => setShowTutorialOverlay(false);

    return (
        <div className="main-content" style={{ marginTop: 0, paddingTop: 20 }}>
            <MenuCard 
                openOverlay={openPrivacyOverlay} 
                userInfo={userInfo} // Pass userInfo to MenuCard
            />
            <ChatCard debugMode={true} />  {/* Pass debugMode prop */}
            {showTutorialOverlay && <TutorialOverlay onClose={closeTutorialOverlay} />}
        </div>
    );
}

export default GPTPage;
