

import React, { useState, useEffect, useRef } from 'react';
import { FaSyncAlt, FaPaperPlane, FaFilePdf, FaCheck, FaExclamationCircle } from 'react-icons/fa'; 
import 'katex/dist/katex.min.css';
import io from 'socket.io-client';
import { useNavigate } from 'react-router-dom';
import { SUGGESTED_QUESTIONS } from './ChatCardConfig';
import './ChatCard.css';
import renderTextWithFormatting from './renderTextWithFormatting';
import referenceLinks from './referenceLinks';
import LazyPlot from './LazyPlot';

localStorage.debug = 'socket.io-client:*';

const ENABLE_VISUALIZATIONS = true;

function ChatCard({ openOverlay, openPrivacyOverlay, selectedClass, debugMode = false }) {  
  const useExampleMessages = false; // Set this to false when deploying
  const socketRef = useRef();
  const [messages, setMessages] = useState([]);
  const [citations, setCitations] = useState([]);
  const processedCitations = useRef(new Set());

  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [visualizationData, setVisualizationData] = useState([]); 
  const messagesEndRef = useRef(null);
  const navigate = useNavigate();
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [suggestedQuestions, setSuggestedQuestions] = useState(SUGGESTED_QUESTIONS);

  const [chatWidth, setChatWidth] = useState(65); 
  const [isDragging, setIsDragging] = useState(false);

  const classTitles = {
    am104: 'AM 104: Complex Analysis',
    physics15a: 'Physics 15a: Introductory Mechanics',
  };

  const classTitle = classTitles[selectedClass] || 'AM104 Chat Interface';

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://gptharvard.uc.r.appspot.com', {
        transports: ['polling'], 
        reconnectionAttempts: 5, 
        timeout: 20000,
        path: '/socket.io',
      });
    }
    
    const socket = socketRef.current;
    socket.on('connect', () => {
      console.log('Connected to server');
    });
    
    socket.on('final_response', (data) => {
      setLoading(false);
      let responseText = data.text_deltas.join('');
      const threadId = data.thread_id;
      const messageIndex = data.message_index;
    

  
      if (data.citations) {
          if (!processedCitations.current.has(messageIndex)) {
              accumulateCitations(data.citations);
              processedCitations.current.add(messageIndex);  // Ensure it's marked as processed after adding
          }
          responseText += `\nReferences: ${data.citations}`;
      }
  
      setMessages((prev) => {
          const newMessages = [...prev];
          if (newMessages.length > 0 && newMessages[newMessages.length - 1].sender === 'bot') {
              newMessages[newMessages.length - 1].text = processReferences(responseText);
              newMessages[newMessages.length - 1].threadId = threadId;
              newMessages[newMessages.length - 1].messageIndex = messageIndex;
          } else {
              newMessages.push({ sender: 'bot', text: processReferences(responseText), threadId, messageIndex });
          }
          return newMessages;
      });
  
      if (data.tool_call_outputs && ENABLE_VISUALIZATIONS) {
        if (data.tool_call_outputs.plot_type === "complex_plot") {
            setVisualizationData((prev) => [
                ...prev, 
                { messageIndex, type: "complex_plot", ...data.tool_call_outputs }
            ]);
        } else {
            setVisualizationData((prev) => [...prev, { messageIndex, ...data.tool_call_outputs }]);
        }
    }
    
    });
  
    socket.on('citations', (data) => {
      setCitations(data.citations);
      if (!processedCitations.current.has(data.messageIndex)) {
          accumulateCitations(data.citations);
          processedCitations.current.add(data.messageIndex);
      }
    });

    // socket.on('citations', (data) => {
    //   console.log({ 'Citations': data });
      
    // });
  
  
    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
      socket.disconnect();
    });
  
    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      socket.disconnect();
    });
  
    socket.on('error', (error) => {
      console.error('Socket.IO Error:', error);
      socket.disconnect();
    });
  
    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected successfully on attempt ${attemptNumber}`);
    });
  
    return () => {
      socket.off('connect');
      socket.off('final_response');
      socket.off('citations');
      socket.off('reconnect_attempt');
      socket.off('connect_error');
      socket.off('reconnect');
    };
  }, [navigate]);

 const accumulateCitations = (newCitations) => {
  console.log('New Citations:', newCitations);  // Log new citations
  
  if (newCitations.length > 0) {
    setCitations((prevCitations) => {
      const updatedCitations = [...prevCitations];
      newCitations.forEach((citation) => {
        if (!processedCitations.current.has(citation)) {  // Check if not processed
          updatedCitations.push(citation);
          processedCitations.current.add(citation);  // Mark as processed
          console.log('Added Citation:', citation);  // Log added citation
        }
      });
      return updatedCitations;
    });
  }
};

  

  const addMessage = () => {
    if (input.trim()) {
      const newUserMessage = { sender: 'user', text: `User: ${input}` };
      socketRef.current.emit('add_message', { message: input, token: localStorage.getItem('token') });
      setMessages((prev) => [...prev, newUserMessage, { sender: 'loading' }]);
      setInput('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      addMessage();
    }
  };

  const createNewThread = () => {
    socketRef.current.emit('create_thread');
    setMessages([]);
    setVisualizationData([]);
    setCitations([]);
    processedCitations.current.clear();  // Clear the processed citations set
  };
  
  const handleQuestionClick = (question) => {
    setInput(question);
    setSuggestedQuestions([]);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (e.target.value) {
      setSuggestedQuestions([]);
    } else {
      setSuggestedQuestions(SUGGESTED_QUESTIONS);
    }
  };

  const toggleCheckboxes = () => {
    setShowCheckboxes(!showCheckboxes);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);
    e.preventDefault();
  };

  const handleScrollToVisualization = (messageIndex) => {
    const visualizationContainer = document.querySelector('.visualization-container');
    const element = document.getElementById(`viz-${messageIndex}`);
    
    if (visualizationContainer && element) {
      const targetPosition = element.offsetTop - visualizationContainer.offsetTop;
      visualizationContainer.scrollTo({
        top: targetPosition,
        behavior: 'smooth'
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!isDragging) {
      const newChatWidth = (e.clientX / window.innerWidth) * 99;
      if (newChatWidth > 20 && newChatWidth < 80) {
        setChatWidth(newChatWidth);
      }
    }
  };

  const processReferences = (text) => {
    const referencePattern = /(\[(\d+)\]\s*([^,\s]+))/g;
    return text.replace(referencePattern, (match, p1, p2, p3) => {
      if (referenceLinks[p3]) {
        return `<a href="${referenceLinks[p3]}" target="_blank">${p1}</a>`;
      }
      return p1;
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    window.removeEventListener('mousemove', handleMouseMove);
    window.removeEventListener('mouseup', handleMouseUp);
  };
  

  useEffect(() => {
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);
  useEffect(() => {
    if (citations.length > 0) {
      console.log('Updated citations:', citations);
    }
  }, [citations]);
  
  useEffect(() => {
    const chatContainer = document.querySelector('.chat-container');
    const visualizationContainer = document.querySelector('.visualization-container');

    const syncScroll = (sourceContainer, targetContainer) => {
      const syncHandler = () => {
        const percentageScrolled = sourceContainer.scrollTop / (sourceContainer.scrollHeight - sourceContainer.clientHeight);
        targetContainer.scrollTop = percentageScrolled * (targetContainer.scrollHeight - targetContainer.clientHeight);
      };
      sourceContainer.addEventListener('scroll', syncHandler);

      return () => {
        sourceContainer.removeEventListener('scroll', syncHandler);
      };
    };

    const cleanupChat = syncScroll(chatContainer, visualizationContainer);
    const cleanupVisualization = syncScroll(visualizationContainer, chatContainer);

    return () => {
      cleanupChat();
      cleanupVisualization();
    };
  }, []);

  // Ensure visualizations are only shown when valid
  const renderVisualizations = (viz) => {
    if (!viz || !viz.x || !viz.y) return null;

    if (viz.type === "complex_plot") {
        return (
            <div id={`viz-${viz.messageIndex}`} className="visualization-card">
                <img src={`data:image/png;base64,${viz.image_data}`} alt="Complex Plot" style={{ width: '100%', height: 'auto' }} />
                <p className="figure-type">
                    Type: Complex Plot, Message {viz.messageIndex}
                </p>
            </div>
        );
    }

    return (
        <div id={`viz-${viz.messageIndex}`} className="visualization-card">
            <LazyPlot
                data={[
                    {
                        x: viz.x,
                        y: viz.y,
                        z: viz.z,
                        type: viz.type === "3D" ? 'surface' : 'contour',
                        colorscale: 'HSV',
                    },
                ]}
                layout={{
                    title: `Visualization`,
                    xaxis: { title: 'Re(z)' },
                    yaxis: { title: 'Im(z)' },
                    zaxis: {
                        title: 'Angle (radians)',
                        tickvals: [-Math.PI, -Math.PI / 2, 0, Math.PI / 2, Math.PI],
                        ticktext: ['-π', '-π/2', '0', 'π/2', 'π'],
                    },
                    autosize: true,
                }}
                style={{ width: '100%', height: 'auto', margin: '0 auto' }}
            />
            <p className="figure-type">
                Type: {viz.type === "3D" ? "3D Surface" : "2D Contour"}, Message {viz.messageIndex}
            </p>
        </div>
    );
};


  return (
   
      
        <div className="chat-card">
          <div className="chat-card-header">
            <h3 className="ghost-title">{classTitle}</h3>
          </div>
          <div className="chat-content">
            <div className="message-visualization-wrapper">
              <div className="chat-container" style={{ width: `${chatWidth}%` }}>
                <div className="messages" ref={messagesEndRef}>
                  {messages.map((message, index) => (
                    <div 
                      key={index} 
                      className={`message-container ${showCheckboxes ? 'with-checkbox' : ''}`}
                      onClick={message.sender === 'bot' ? () => handleScrollToVisualization(message.messageIndex) : null}
                    >
                      {showCheckboxes && (
                        <input type="checkbox" defaultChecked className="message-checkbox" />
                      )}

                      <div className={`message ${message.sender}`}>
                        {message.sender === 'loading' ? (
                          <div className="loading-dots"></div>
                        ) : (
                          <>
                            {renderTextWithFormatting(message.text)}
                            {message.sender === 'bot' && (
                              <>
                                <div className="message-meta">
                                  Thread ID: {message.threadId}, Message Index: {message.messageIndex}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="divider" onMouseDown={handleMouseDown}>
                <div className={`slider ${isDragging ? 'active' : ''}`} />
              </div>

              <div className="visualization-container" style={{ width: `${100 - chatWidth}%` }}>
                {/* Render visualizations and citations interleaved */}
                {messages.map((message, index) => {
                  const viz = visualizationData.find(v => v.messageIndex === message.messageIndex);
                  const citation = citations.find(c => c.messageIndex === message.messageIndex);

                  return (
                    <div key={index}>
                      {viz && renderVisualizations(viz)}
                      
                      {/* {citation && (
                        <div className="citation-card">
                          <FaCheck className="checkmark-icon" />
                          <span dangerouslySetInnerHTML={{ __html: processReferences(`Referencing from AM104 course material ${citation}`) }} />
                        </div>
                      )} */}
                      {citations.length > 0 && (
                        <div className="citation-card pinned-citation">
                          <FaCheck className="checkmark-icon" />
                          <span dangerouslySetInnerHTML={{ __html: processReferences(`Referencing from AM104 course material ${citations[citations.length - 1]}`) }} />
                        </div>
                      )}



                    </div>
                  );
                })}
              </div>
            </div>

            {suggestedQuestions.length > 0 && (
              <div className="suggested-questions">
                {suggestedQuestions.map((question, index) => (
                  <div
                    key={index}
                    className="suggested-question"
                    onClick={() => handleQuestionClick(question)}
                  >
                    {question}
                  </div>
                ))}
              </div>
            )}
            <div className="input-container">
              <input
                type="text"
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Enter your message..."
                className="chat-input"
              />
              <button className="send" onClick={addMessage}>
                <FaPaperPlane />
              </button>
              <button className="refresh" onClick={createNewThread}>
                <FaSyncAlt className="icon" />
              </button>
              <button className={`pdf-button ${showCheckboxes ? 'active' : ''}`} onClick={toggleCheckboxes}>
                <FaFilePdf className="pdf-icon" />
              </button>
            </div>
            
            <p className="disclaimer-text">
              This ChatBot can make mistakes, so please verify information before using. See <a href="#" onClick={openPrivacyOverlay}>privacy and information policy</a>.
            </p>
          </div>
        </div>
      
   
  );
}

export default ChatCard;

