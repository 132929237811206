// AdminPage.js
import React, { useEffect, useState } from 'react';
import './AdminPage.css'; // Import CSS for styling
import { FaFileAlt, FaEdit, FaSlidersH, FaLightbulb, FaFileUpload } from 'react-icons/fa'; // Importing icons from react-icons

// Import necessary components from Chart.js
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// Register components to use them in the chart
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function AdminPage({ userInfo }) {
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        // Check if the user has any of the required roles
        const roles = userInfo.roles;
        if (roles.includes('admin') || roles.includes('instructor') || roles.includes('TF')) {
            setHasAccess(true);
        }
    }, [userInfo]);

    if (!hasAccess) {
        return <div>Access Denied</div>; // Display access denied if the user does not have proper roles
    }

    return (
        <div className="admin-page">
            {/* Sidebar for admin controls */}
            <div className="sidebar">
                <div className="admin-info">
                    <h2>{userInfo.firstName}</h2>
                    <h4>Course: {userInfo.courses.join(', ') || 'No courses assigned'}</h4>
                </div>
            </div>

            {/* Main content area for General tab */}
            <div className="content">
                {/* Large Cards */}
                <div className="large-card">
                    <h3>User Management</h3>
                    <p>Check Firebase for user management.</p>
                    <a href="https://console.firebase.google.com/u/0/project/harvard-physics-hosting/authentication/users" target="_blank" rel="noopener noreferrer">
                        <button className="minimal-button">
                            <FaEdit size={20} /> Check Firebase
                        </button>
                    </a>
                </div>
                
                <div className="large-card">
                    <h3>Chatbot Usage Statistics</h3>
                    <p>Statistics and analytics about chatbot usage.</p>
                    {/* Example Bar Chart */}
                    <Bar
                        data={{
                            labels: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5'],
                            datasets: [
                                {
                                    label: '# of Users',
                                    data: [12, 19, 3, 5, 2],
                                    backgroundColor: 'rgba(0, 123, 255, 0.5)',
                                    borderColor: 'rgba(0, 123, 255, 1)',
                                    borderWidth: 1,
                                },
                            ],
                        }}
                        options={{
                            scales: {
                                y: {
                                    beginAtZero: true,
                                },
                            },
                        }}
                    />
                </div>
                
                <div className="large-card">
                    <h3>Content and Prompt Management</h3>
                    <p>Manage chatbot's response content and predefined prompts.</p>
                    <div className="prompt-icons">
                        <button className="minimal-button">
                            <FaFileAlt size={24} /> Edit Files Available
                        </button>
                        <button className="minimal-button">
                            <FaSlidersH size={24} /> Edit Limitations
                        </button>
                        <button className="minimal-button">
                            <FaLightbulb size={24} /> Pedagogical Input
                        </button>
                    </div>
                </div>
                
                <div className="large-card">
                    <h3>System Settings</h3>
                    <p>Manage technical and configuration settings of the chatbot.</p>
                    <button className="minimal-button">
                        <FaFileUpload size={24} /> File Input
                    </button>
                </div>

                {/* Small Cards */}
                <div className="small-card">
                    <h4>Quick Actions</h4>
                    <button className="minimal-button">
                        <FaEdit size={20} /> Add User
                    </button>
                    <button className="minimal-button">
                        <FaEdit size={20} /> Generate Report
                    </button>
                    <button className="minimal-button">
                        <FaEdit size={20} /> Clear Logs
                    </button>
                </div>
                
                <div className="small-card">
                    <h4>Recent Activity</h4>
                    <p>List of recent changes or actions taken.</p>
                </div>
                
                <div className="small-card">
                    <h4>Alerts and Notifications</h4>
                    <p>Display any critical issues or warnings.</p>
                </div>
                
                <div className="small-card">
                    <h4>Feedback and Suggestions</h4>
                    <p>Display feedback from users.</p>
                </div>
            </div>
        </div>
    );
}

export default AdminPage;
