// referenceLinks.js
const referenceLinks = {
    'am104_section7.pdf': 'http://example.com/am104_section7',
    'am104_section7_solution.pdf': 'http://example.com/am104_section7_sol',
    'LearningOutcomes.pdf': 'http://example.com',
    'am104_section8_solution.pdf': 'http://example.com/am104_section8_sol',

  };
  
  export default referenceLinks;
  