// MenuCard.js
import React from 'react';
import './MenuCard.css'; 
import harvardLogo2 from './AM104.png';
import MenuButton from './MenuButton';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase'; // Added this import

function MenuCard({ openPrivacyOverlay, userInfo = { firstName: 'User', roles: [], courses: [] }, status }) { 
    const navigate = useNavigate(); // useNavigate hook for redirection

    // Destructure userInfo, with fallback values
    const { firstName, roles, courses } = userInfo;

    const logout = async () => {
        try {
            await auth.signOut(); // Sign out from Firebase
            navigate('/'); // Redirect to the landing page
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <div className="menu-card">
            <div className="logo-placeholder">
                <img 
                    src={harvardLogo2} 
                    alt='AM104 logo'
                    style={{ width: '30%', height: 'auto', borderRadius: '15px' }}
                />
                <h2 className="centered">AM104</h2>
            </div>

            {/* Greeting and roles display */}
            <h2 className="centered">Hello, {firstName}</h2>
            <h4 className="centered">
                Role: <strong>{roles.join(', ')}</strong> in {courses.length > 0 ? courses.join(', ') : 'No courses assigned.'}
            </h4>

            <div className="horizontal-bar"></div>

            <div className="tab-content menu-buttons-container">
                <MenuButton 
                    color="#EF4444" 
                    icon="🔒" 
                    title="Privacy Policy" 
                    onClick={openPrivacyOverlay} 
                />
                {/* Add more menu buttons as needed */}
            </div>

            <div className="horizontal-bar"></div>

            <div 
                className={`status-box ${status === 'Connected' ? 'connected' : 'disconnected'}`} 
            >
                Status: {status}
            </div>

            {/* Logout button */}
            <button className="logout-button" onClick={logout}>Logout</button>
        </div>
    );
}

export default MenuCard;
