// NewGPT.js
import React, { useState, useEffect, useRef } from 'react';
import ReactFlow, { addEdge, Handle, Background, Controls, MiniMap, ReactFlowProvider,} from 'react-flow-renderer';
import dagre from 'dagre';
import io from 'socket.io-client';
import { FaPaperPlane, FaSyncAlt } from 'react-icons/fa';
import 'katex/dist/katex.min.css';
import renderTextWithFormatting from './renderTextWithFormatting';
import harvardLogo2 from './AM104.png';
import placeholderImage from './placeholder-image.png';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import Plot from 'react-plotly.js';
import './App.css';

const ENABLE_VISUALIZATIONS = true;

// Define the initial options outside the component
const initialOptions = [
  '  What happened this week in lecture / section?',
  '  Give me an example problem!',
  '  Show a visual!',
];

function NewGPT({
  openPrivacyOverlay,
  openTutorialOverlay,
  openExamplesOverlay,
  userInfo = { firstName: 'User', roles: [], courses: [] },
  status = 'Connected',
  selectedClass = 'AM104',
}) {
  // Refs and State Variables
  const socketRef = useRef();
  const nextIdRef = useRef(1);
  const resizingNodeRef = useRef(null);
  const flowRef = useRef(null);

  const navigate = useNavigate(); // For logout

  // State for reactFlowInstance
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  // Handle option click from the initial options node
  const handleOptionClick = (optionText) => {
    addUserMessage(optionText);
  };

  const [input, setInput] = useState('');

  // Function to send user message
  const addUserMessage = (messageText = input) => {
    if (messageText.trim()) {
      // Emit the message to the server
      socketRef.current.emit('add_message', {
        message: messageText,
        token: localStorage.getItem('token'), // Include token if necessary
      });

      // Create user node
      const userId = getNextId();
      const userNode = {
        id: userId,
        type: 'user',
        data: { label: `${messageText}` },
        position: { x: 0, y: 0 }, // Position will be set by layout
      };

      // Create bot node with empty label and assign isPlaceholder
      const botNodeId = getNextId();
      const botNode = {
        id: botNodeId,
        type: 'chatbot',
        data: { label: '', hasVisual: false, isPlaceholder: true },
        position: { x: 0, y: 0 }, // Position will be set by layout
      };

      // Update nodes and edges
      setNodes((prevNodes) => {
        // Remove initial options node if it exists
        const updatedNodes = prevNodes.filter(
          (node) => node.id !== 'initial-options'
        );
        updatedNodes.push(userNode, botNode);
        nodesRef.current = updatedNodes;

        // Re-layout nodes
        const { nodes: layoutedNodes } = getLayoutedElements(
          updatedNodes,
          edgesRef.current
        );
        nodesRef.current = layoutedNodes;
        return layoutedNodes;
      });

      // Create edges
      setEdges((prevEdges) => {
        const updatedEdges = [...prevEdges];

        // Edge from user node to bot node
        updatedEdges.push({
          id: `e${userId}-${botNodeId}`,
          source: userId,
          target: botNodeId,
          type: 'smoothstep',
          animated: true,
        });

        // Edge from previous bot node to current user node (if any)
        const prevBotNode = nodesRef.current
          .slice(0, -2) // Exclude the last two nodes (current user and bot nodes)
          .reverse()
          .find((node) => node.type === 'chatbot');

        if (prevBotNode) {
          updatedEdges.push({
            id: `e${prevBotNode.id}-${userId}`,
            source: prevBotNode.id,
            target: userId,
            type: 'smoothstep',
            animated: true,
          });
        }

        edgesRef.current = updatedEdges;
        return updatedEdges;
      });

      // Clear input
      setInput('');
    }
  };

  // Initialize nodes with the initial options node
  const [nodes, setNodes] = useState([
    {
      id: 'initial-options',
      type: 'initialOptions',
      data: { onOptionClick: handleOptionClick },
      position: { x: 0, y: 0 }, // Position will be set by layout
    },
  ]);
  const [edges, setEdges] = useState([]);
  const [transform, setTransform] = useState({ x: 0, y: 0, zoom: 1 });

  // Reference to the latest nodes and edges
  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Update refs when state changes
  useEffect(() => {
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    edgesRef.current = edges;
  }, [edges]);

  // Use dagre layout
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 500; // Use the node width we set in CSS
  const nodeHeight = 100; // Approximate node height

  const getLayoutedElements = (nodes, edges, direction = 'TB') => {
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction, nodesep: 50, ranksep: 100 });

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';

      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2 + 125, // Adjust for sidebar width
        y: nodeWithPosition.y - nodeHeight / 2,
      };

      return node;
    });

    return { nodes: layoutedNodes, edges };
  };

  // Re-layout on nodes or edges change
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const { nodes: layoutedNodes } = getLayoutedElements(
        nodesRef.current,
        edgesRef.current
      );
      if (JSON.stringify(layoutedNodes) !== JSON.stringify(nodesRef.current)) {
        setNodes(layoutedNodes);
      }
    }, 100); // Adjust the debounce time as needed
  
    return () => clearTimeout(debounceTimeout);
  }, [nodes, edges]);
  

  // Scroll to the latest node
  useEffect(() => {
    // if (nodes.length > 0 && reactFlowInstance) {
    //   const latestNode = nodes[nodes.length - 1];
    //   reactFlowInstance.setCenter(
    //     latestNode.position.x + nodeWidth / 2,
    //     latestNode.position.y + nodeHeight / 2,
    //     {
    //       zoom: 1,
    //       duration: 500,
    //     }
    //   );
    // }
  }, [nodes, reactFlowInstance]);

  // Initialize Socket Connection
  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io('https://gptharvard.uc.r.appspot.com', {
        transports: ['polling'],
        reconnectionAttempts: 5,
        timeout: 20000,
        path: '/socket.io',
      });
    }

    const socket = socketRef.current;
    socket.on('connect', () => {
      console.log('Connected to server');
    });

    socket.on('final_response', (data) => {
    console.log('Received final_response:', data);  // Debug log

      handleSocketResponse(data);
    });

    socket.on('error', (error) => {
      console.error('Socket.IO Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect_attempt', (attempt) => {
      console.log(`Reconnect attempt ${attempt}`);
      socket.disconnect();
    });

    socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      socket.disconnect();
    });

    socket.on('reconnect', (attemptNumber) => {
      console.log(`Reconnected successfully on attempt ${attemptNumber}`);
    });

    return () => {
      socket.off('connect');
      socket.off('final_response');
      socket.off('error');
      socket.off('reconnect_attempt');
      socket.off('connect_error');
      socket.off('reconnect');
    };
  }, []);

  // Function to get the next unique ID
  const getNextId = () => {
    const id = nextIdRef.current.toString();
    nextIdRef.current += 1;
    return id;
  };

  // Function to handle final socket responses
  const handleSocketResponse = (data) => {
    console.log('data.done:', data.done);  // Log the done status
    console.log('data.citations:', data.citations);  // Log the citations
  
    let responseText = '';
  
    if (Array.isArray(data.text_deltas) && data.text_deltas.length > 0) {
      responseText = data.text_deltas.join('');
    } else if (typeof data.text === 'string' && data.text.trim() !== '') {
      responseText = data.text;
    } else if (data.tool_call_outputs) {
      responseText = 'Here is the visualization:';
    } else {
      console.warn('No text_deltas or text in data:', data);
      responseText = 'No response received.';
    }
  
    let botNodeId = null;
  
    setNodes((prevNodes) => {
      let updatedNodes = [...prevNodes];
      let hasChanged = false;
      for (let i = updatedNodes.length - 1; i >= 0; i--) {
        if (updatedNodes[i].type === 'chatbot' && updatedNodes[i].data.isPlaceholder) {
          const newData = {
            ...updatedNodes[i].data,
            label: responseText,
            isPlaceholder: !data.done,
          };
          if (JSON.stringify(updatedNodes[i].data) !== JSON.stringify(newData)) {
            updatedNodes[i] = {
              ...updatedNodes[i],
              data: newData,
            };
            botNodeId = updatedNodes[i].id;
            hasChanged = true;
          }
          break;
        }
      }
      if (hasChanged) {
        nodesRef.current = updatedNodes;
        return updatedNodes;
      }
      return prevNodes; // No update if nothing has changed
    });

    if (data.citations && data.citations.length > 0) {
      const citations = data.citations.join('\n');
      
      const refNodeId = getNextId();
      const botNodePosition = nodesRef.current.find((node) => node.id === botNodeId).position;

    // Create the ReferenceNode and position it relative to the chatbot node
    const refNode = {
      id: refNodeId,
      type: 'reference',  // Use the ReferenceNode type
      data: { references: data.citations },  // Pass the citations to the node
      position: { x: botNodePosition.x + 525, y: botNodePosition.y },  // Position to the right of chatbot node
    };

      // Add the ReferenceNode
      setNodes((prevNodes) => {
        const updatedNodes = [...prevNodes, refNode];
        nodesRef.current = updatedNodes;
        return updatedNodes;
      });
  
      // Connect the ReferenceNode to the chatbot node
      setEdges((prevEdges) => {
        const updatedEdges = [
          ...prevEdges,
          {
            id: `e${botNodeId}-${refNodeId}`,
            source: botNodeId,
            target: refNodeId,
            type: 'smoothstep',
            animated: true,
          },
        ];
        edgesRef.current = updatedEdges;
        return updatedEdges;
      });
    }
  };
  
  const handleVisualization = (vizData, botNodeId) => {
    console.log('Visualization data received in handleVisualization:', vizData);
  
    if (!botNodeId) {
      console.error('botNodeId is missing!');
      return;
    }
  
    const vizNodeId = getNextId();
    let vizNode;
  
    if (vizData.plot_type === "complex_plot") {
      // Handle complex_plot visualization
      vizNode = {
        id: vizNodeId,
        type: 'visual',
        data: {
          imageData: vizData.image_data,
          id: vizNodeId,
          onResizeStart: handleResizeStart,
        },
        position: { x: 200, y: 400 },
        style: { width: 500, height: 300 },
      };
    } else {
      // Handle 2D and 3D plots
      vizNode = {
        id: vizNodeId,
        type: 'visual',
        data: {
          chartData: [
            {
              x: vizData.x,
              y: vizData.y,
              z: vizData.z,
              type: vizData.type === "3D" ? 'surface' : 'contour',
              colorscale: 'HSV',
            },
          ],
          layout: {
            title: 'Visualization',
            xaxis: { title: 'Re(z)' },
            yaxis: { title: 'Im(z)' },
            zaxis: {
              title: 'Angle (radians)',
              tickvals: [-Math.PI, -Math.PI / 2, 0, Math.PI / 2, Math.PI],
              ticktext: ['-π', '-π/2', '0', 'π/2', 'π'],
            },
            autosize: true,
          },
          id: vizNodeId,
          onResizeStart: handleResizeStart,
        },
        position: { x: 200, y: 400 },
        style: { width: 500, height: 300 },
      };
    }
  
    console.log('Adding visualization node:', vizNode);
  
    setNodes((prevNodes) => {
      const updatedNodes = [...prevNodes, vizNode];
      nodesRef.current = updatedNodes;
      return updatedNodes;
    });
  
    setEdges((prevEdges) => {
      const updatedEdges = [...prevEdges];
      const prevEdgeLength = prevEdges.length;
    
      // Avoid unnecessary edge updates
      if (updatedEdges.length !== prevEdgeLength) {
        edgesRef.current = updatedEdges;
        return updatedEdges;
      }
    
      return prevEdges;
    });
    
  };

  
 
  
  // Handle node resizing
  const handleResizing = (e) => {
    if (resizingNodeRef.current) {
      setNodes((prevNodes) =>
        prevNodes.map((node) => {
          if (node.id === resizingNodeRef.current) {
            const newWidth = Math.min(
              Math.max(150, e.clientX - node.position.x),
              window.innerWidth - node.position.x
            );
            const newHeight = Math.min(
              Math.max(100, e.clientY - node.position.y),
              window.innerHeight - node.position.y
            );

            return {
              ...node,
              style: {
                ...node.style,
                width: newWidth,
                height: newHeight,
              },
            };
          }
          return node;
        })
      );
    }
  };

  const handleResizeStart = (e, nodeId) => {
    e.preventDefault();
    e.stopPropagation();
    resizingNodeRef.current = nodeId;
    window.addEventListener('mousemove', handleResizing);
    window.addEventListener('mouseup', handleResizeEnd);
  };

  const handleResizeEnd = () => {
    resizingNodeRef.current = null;
    window.removeEventListener('mousemove', handleResizing);
    window.removeEventListener('mouseup', handleResizeEnd);
  };

  // Logout function
  const logout = async () => {
    try {
      await auth.signOut(); // Sign out from Firebase
      navigate('/'); // Redirect to the landing page
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Custom Node Components

  // UserNode component
  const UserNode = React.memo(({ data }) => (
    <div className="user-node">
      <div>{renderTextWithFormatting(data.label)}</div>
      <Handle type="source" position="bottom" />
    </div>
  ));
  
  const ChatbotNode = React.memo(({ data }) => (
    <div className={`chatbot-node ${data?.hasVisual ? 'small-width' : ''}`}>
      <div>{renderTextWithFormatting(data.label)}</div>
      <Handle type="target" position="top" />
      {data?.hasVisual && <Handle type="source" position="bottom" />}
    </div>
  ));

  // ReferenceNode component
  const ReferenceNode = ({ data }) => (
    <div className="reference-node">
      <div>
        {renderTextWithFormatting(
          `References: ${data.references.join(', ')}`
        )}
      </div>
      <Handle type="target" position="top" />
    </div>
  );

  // Initial Options Node Component
  const InitialOptionsNode = React.memo(({ data }) => (
    <div className="initial-options-node">
      <h2 style={{ fontSize: '12px' }}>Hey! Choose an option</h2>
      <div className="options-container">
        {initialOptions.map((optionText, index) => (
          <OptionCard
            key={index}
            text={optionText}
            onClick={() => data.onOptionClick(optionText)}
            imageSrc={placeholderImage}
          />
        ))}
      </div>
    </div>
  ));

  const OptionCard = ({ text, onClick, imageSrc }) => {
    const [displayedText, setDisplayedText] = useState('');

    useEffect(() => {
      let index = 0;
      const interval = setInterval(() => {
        setDisplayedText((prev) => prev + text.charAt(index));
        index++;
        if (index >= text.length) {
          clearInterval(interval);
        }
      }, 30);
      return () => clearInterval(interval);
    }, [text]);

    return (
      <div className="option-card" onClick={onClick}>
        <img src={imageSrc} alt="Option" />
        <p>{displayedText}</p>
      </div>
    );
  };

  // Updated VisualNode Component
  const VisualNode = ({ data, style }) => {
    const nodeStyle = style || {
      width: 500,
      height: 300,
      border: '1px solid #ccc',
      backgroundColor: '#fff',
    };
  
    console.log('Rendering VisualNode with data:', data);
  
    return (
      <div className="visual-node" style={nodeStyle}>
        {data.imageData ? (
          // Render complex plot image
          <img
            src={`data:image/png;base64,${data.imageData}`}
            alt="Complex Plot"
            style={{ width: '100%', height: 'auto' }}
          />
        ) : data.chartData ? (
          // Render 2D or 3D plot
          <Plot
            data={data.chartData}
            layout={data.layout || { title: 'Visualization' }}
            style={{ width: '100%', height: '100%' }}
            useResizeHandler={true}
          />
        ) : (
          <div className="visual-placeholder">No Data Available</div>
        )}
        <div
          className="resize-handle"
          onMouseDown={(e) => data.onResizeStart(e, data.id)}
        ></div>
        <Handle type="target" position="top" />
      </div>
    );
  };

  // Define nodeTypes
  const nodeTypes = {
    user: UserNode,
    chatbot: ChatbotNode,
    reference: ReferenceNode,
    visual: VisualNode,  // Ensure this matches
    initialOptions: InitialOptionsNode,
  };
  
return (
  <div className="newgpt-container">
    {/* ReactFlow as full background */}
    <div className="flow-wrapper">
      <ReactFlowProvider>
        <ReactFlow
          ref={flowRef}
          nodes={nodes}
          edges={edges}
          onConnect={(params) => setEdges((eds) => addEdge(params, eds))}
          transform={transform}
          zoomOnScroll={false}
          zoomOnPinch={false}
          panOnScroll={true}
          panOnScrollMode="vertical"
          nodeTypes={nodeTypes}
          fitView
          onInit={(instance) => setReactFlowInstance(instance)}
          className="reactflow-background"
        >
          <Background />
          <Controls />
          <MiniMap />
        </ReactFlow>
      </ReactFlowProvider>
    </div>

    {/* Sidebar Menu */}
    <div className="sidebar-menu">
      <div className="logo-placeholder">
        <img
          src={harvardLogo2}
          alt="Logo"
          className="logo-image"
        />
      </div>

      {/* Greeting and roles display */}
      <h2 className="centered black-text">Hello, {userInfo.firstName}</h2>
      <h4 className="centered black-text">
        Role: <strong>{userInfo.roles.join(', ')}</strong> in{' '}
        {userInfo.courses.length > 0
          ? userInfo.courses.join(', ')
          : 'No courses assigned.'}
      </h4>

      
    
        <button className="menu-button" onClick={openPrivacyOverlay}>
          🔧 Settings
        </button>

      <button className="menu-button" onClick={openPrivacyOverlay}>
        🔒 Privacy Policy
      </button>

      <button className="menu-button" onClick={openPrivacyOverlay}>
          🔧 Known Bugs (LOL)
        </button>
      
      <button className="logout-button" onClick={logout}>
        Logout
      </button>
    </div>


    <div className="floating-bar">
      <input
        type="text"
        className="floating-input"
        placeholder="Type here..."
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && input.trim()) {
            event.preventDefault();
            addUserMessage();
          }
        }}
      />
      <button className="floating-button" onClick={addUserMessage}>
        <FaPaperPlane />
      </button>
      
      <button
        className="refresh-button"
        onClick={() => {
          setNodes([
            {
              id: 'initial-options',
              type: 'initialOptions',
              data: { onOptionClick: handleOptionClick },
              position: { x: 0, y: 0 },
            },
          ]);
          setEdges([]);
          nextIdRef.current = 1;
        }}
      >
        <FaSyncAlt />
      </button>
    </div>
  </div>
);

  
}

export default NewGPT;
